import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 登录
 */
const PostLogin = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/login", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 退出登录
 * @param vCom Vue组件
 */
const LoginOut = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/user/logout").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获取当前用户信息，如果传了project_id则是获取当前专病的用户信息
 * @param vCom Vue组件
 */
const GetCurrentUserData = (vCom: any, params?: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/user", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 改变账户名
 * @param vCom Vue组件
 * @param params 参数
 */
const PostChangeAccountName = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/user_name", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 改变密码
 * @param vCom Vue组件
 * @param params 参数
 */
const PostChangePassword = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/pc/password", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 改变手机号
 * @param vCom Vue组件
 * @param params 参数
 */
const PostChangePhone = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user/phone", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 发送手机验证码
 * @param vCom Vue组件
 * @param phone 手机号
 */
const PostPhoneCode = (vCom: any, phone: string | undefined, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        const params: any = {
            手机号: phone || ''
        };
        axios.post("api/user/phonecode", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 改变用户信息
 * @param vCom Vue组件
 */
const PostUserData = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/user", params, loading).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
export {
    PostLogin, LoginOut, GetCurrentUserData, PostChangeAccountName,
    PostChangePassword, PostChangePhone, PostPhoneCode, PostUserData
}

